import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBase/MDBox";
import MDInput from "components/MDBase/MDInput";
import Breadcrumbs from "components/MDComponents/Breadcrumbs";
import LogoutModal from "components/Modals/LogoutModal";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/MDComponents/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setDarkMode,
} from "context/md";
import { allSites, selectedSites } from "signals/group_signals";
import { v4 as uuidv4 } from "uuid";
import MDTypography from "../../../MDBase/MDTypography";
import Popper from "@mui/material/Popper";

function DashboardNavbar({ absolute, light, isMini, title, hideSites }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  const [logoutModal, setLogoutModal] = useState(false);
  const handleOpenLogoutModal = () => setLogoutModal(true);
  const handleCloseLogoutModal = () => setLogoutModal(false);

  const [autoKey, setAutoKey] = useState(1);

  useEffect(() => {
    if (fixedNavbar) setNavbarType("sticky");
    else setNavbarType("static");

    const handleTransparentNavbar = () =>
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: light || darkMode ? white.main : transparentNavbar && !light ? darkMode ? rgba(text.main, 0.6) : text.main : dark.main,
  });

  const infoStyle = ({ palette: { info } }) => ({ color: info.main });

  const handleGroupClick = (groupDescription) => {
    const groupItems = allSites.value.filter((option) => option.groupDescription === groupDescription);
    const groupIds = groupItems.map((item) => item.id);
    const currentIds = selectedSites.value.map((item) => item.id);

    const isGroupSelected = groupIds.every((id) => currentIds.includes(id));

    if (isGroupSelected) {
      selectedSites.value = selectedSites.value.filter((item) => !groupIds.includes(item.id));
    } else {
      selectedSites.value = [...selectedSites.value, ...groupItems];
    }

    setAutoKey(uuidv4());
  };

  const CustomPopper = (props) => {
    return <Popper {...props} sx={{ width: 'auto', minWidth: 'fit-content', maxWidth: '100%', zIndex: 1300 }} />;
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
            overrideTitle={title}
          />
          {!hideSites && (
            <Autocomplete
              key={autoKey}
              PopperComponent={CustomPopper}
              sx={{ paddingLeft: "1rem", minWidth: "11rem", paddingRight: isMini ? "" : "1rem" }}
              multiple
              limitTags={2}
              value={selectedSites.value}
              groupBy={(option) => option.groupDescription}
              options={allSites.value}
              onChange={(event, value) => {
                selectedSites.value = value;
                setAutoKey(uuidv4());
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.description}
              renderGroup={(params) => (
                <React.Fragment key={params.key}>
                  <ListItem
                    button
                    onClick={() => handleGroupClick(params.group)}
                    sx={{ cursor: "pointer",  }}
                  >

                    {/*<ListItemText primary={params.group} />*/}
                    <MDBox display="flex" alignItems="center" borderRadius="md" sx={{width:"100%", px:1, backgroundColor:"rgba(128,128,128,0.2)", "&:hover": { backgroundColor: "rgba(128,128,128,0.2)" }}}>
                      <Checkbox
                        size="small"
                        checked={allSites.value
                          .filter((option) => option.groupDescription === params.group)
                          .every((item) => selectedSites.value.some((sel) => sel.id === item.id))}
                        indeterminate={allSites.value
                          .filter((option) => option.groupDescription === params.group)
                          .some((item) => selectedSites.value.some((sel) => sel.id === item.id))}
                      />
                      <MDTypography variant="h6" >{params.group}</MDTypography>
                    </MDBox>
                  </ListItem>
                  {params.children}
                </React.Fragment>
              )}
              renderInput={(params) => <MDInput label="Sites" {...params} />}
            />
          )}
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {!isMini && (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                sx={navbarIconButton}
                size="small"
                disableRipple
                onClick={() => navigate("/profile")}
              >
                <Icon sx={route[0] === "profile" ? infoStyle : iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton size="small" disableRipple color="inherit" onClick={handleDarkMode}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {darkMode ? "dark_mode" : "light_mode"}
                </Icon>
              </IconButton>
              <IconButton
                sx={navbarIconButton}
                size="small"
                disableRipple
                onClick={handleOpenLogoutModal}
              >
                <Icon sx={iconsStyle}>logout</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <LogoutModal handleClose={handleCloseLogoutModal} open={logoutModal} />
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  hideSites: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  hideSites: PropTypes.bool,
};

export default DashboardNavbar;
